import React from "react";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import images from "../../assets/images";
import "./index.scss";
import { firstDoseState } from "../../atoms/firstDoes";
import { useRecoilState } from "recoil";
import http from "../../utility/http";
import { useSelector } from "react-redux";

const ViewTransferRequestModal = (props) => {
  const currentUser = useSelector((state) => state.currentUser);
  const getStatusStyle = () => {
    switch (props?.request?.StatusId) {
      case 1:
      case 2:
        return "warning";
      case 3:
        return "success";
      case 4:
      case 5:
        return "danger";
      default:
        return "";
    }
  };

  const getStatusIcon = () => {
    switch (props?.request?.StatusId) {
      case 1:
        return "fa-solid fa-circle-exclamation";
      case 2:
        return "fa-solid fa-circle-exclamation";
      case 3:
        return "fa-solid fa-check-circle";
      case 4:
        return "fas fa-ban";
      case 5:
        return "fas fa-times";
      default:
        return "";
    }
  };

  const getStatusMessage = () => {
    switch (props?.request?.StatusId) {
      case 1:
        return (
          <span className="w-100">Transfer request is pending acceptance</span>
        );
      case 2:
        return (
          <span className="w-100">Transfer request is pending review</span>
        );
      case 3:
        return (
          <>
            <span className="w-100">
              Transfer request was accepted by
              <b>
                &nbsp;
                {props?.request?.ActionByPharmacistName ||
                  props?.request?.integrationData?.changeStatusBy}
                &nbsp;
              </b>{" "}
              on
              <b>
                &nbsp;
                {moment(
                  props.request.StatusChangedOn ||
                    props?.request?.integrationData?.changeStatusOn
                )
                  ?.format("MMMM DD YYYY")
                  .replace("Invalid date", "Never filled")}
                &nbsp;
              </b>
              at
              <b>
                &nbsp;
                {moment(
                  props.request.StatusChangedOn ||
                    props?.request?.integrationData?.changeStatusOn
                )
                  ?.format("h:mm:ss a")
                  .replace("Invalid date", "Never filled")}
              </b>
              {props?.request?.ActionReasonText?.length > 0 && (
                <div>Reason: {props.request.ActionReasonText}</div>
              )}
            </span>
          </>
        );
      case 4:
        return (
          <span className="w-100">
            Transfer request was rejected by
            <b>
              &nbsp;
              {props?.request?.ActionByPharmacistName ||
                props?.request?.integrationData?.changeStatusBy}
              &nbsp;
            </b>{" "}
            on
            <b>
              &nbsp;
              {moment(
                props.request.StatusChangedOn ||
                  props?.request?.integrationData?.changeStatusOn
              )
                ?.format("MMMM DD YYYY")
                .replace("Invalid date", "Never filled")}
              &nbsp;
            </b>
            at
            <b>
              &nbsp;
              {moment(
                props.request.StatusChangedOn ||
                  props?.request?.integrationData?.changeStatusOn
              )
                ?.format("h:mm:ss a")
                .replace("Invalid date", "Never filled")}
            </b>
            <br />
            Reason:
            <b>
              &nbsp;
              {props.request.ActionReasonText ||
                props?.request?.integrationData?.reason}
            </b>
          </span>
        );
      case 5:
        return (
          <span className="w-100">
            Request was canceled by
            <b>
              &nbsp;
              {props?.request?.ActionByPharmacistName ||
                props?.request?.integrationData?.changeStatusBy}
              &nbsp;
            </b>{" "}
            on
            <b>
              &nbsp;
              {moment(
                props.request.StatusChangedOn ||
                  props?.request?.integrationData?.changeStatusOn
              )
                ?.format("MMMM DD YYYY")
                .replace("Invalid date", "Never filled")}
              &nbsp;
            </b>
            at
            <b>
              &nbsp;
              {moment(
                props.request.StatusChangedOn ||
                  props?.request?.integrationData?.changeStatusOn
              )
                ?.format("h:mm:ss a")
                .replace("Invalid date", "Never filled")}
            </b>
            <br />
            Reason:
            <b>
              &nbsp;
              {props.request.ActionReasonText ||
                props?.request?.integrationData?.reason}
            </b>
          </span>
        );
      default:
        return null;
    }
  };
  const [firstDose, setFirstDose] = useRecoilState(firstDoseState);
  const inFirstDose = firstDose.inFirstDose(
    props?.request?.FromPharmacyId,
    firstDose.pharmacies
  );
  console.log(" props?.request", props?.request?.trxTraceId);
  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      aria-labelledby="view-transfer-request-modal-title"
      centered
      backdrop="static"
      keyboard={false}
      scrollable
      fullscreen="lg-down"
      dialogClassName="modal-xxl"
    >
      <Modal.Header closeButton className="d-print-none">
        <div className="btn-group" role="group" aria-label="Basic example">
          <button type="button" className="btn btn-secondary" title="Previous">
            <i className="fa-solid fa-chevron-left"></i>
          </button>
          <button type="button" className="btn btn-secondary" title="Next">
            <i className="fa-solid fa-chevron-right"></i>
          </button>
        </div>
        <Modal.Title id="view-transfer-request-modal-title" className="ms-3">
          View Transfer {props.requesttype === 1 ? "Out" : "In"} Request Details
          #{props?.request?.RequestId}
          <button
            className="btn btn-default"
            type="button"
            onClick={async () => {
              ///
              props.handleManualAutoCompleteRequestModalShow(true);
            }}
            style={{
              marginLeft: 20,
            }}
          >
            <span>Manual auto complete request</span>
          </button>
          {inFirstDose && props?.request?.StatusName === "Accepted" && (
            <button
              className="btn btn-default"
              type="button"
              onClick={async () => {
                const response = await http.post(
                  "/v1/transfer/send-ready-transfer-notification",
                  {
                    request: props?.request,
                  }
                );
                console.log(response);
              }}
              style={{
                marginLeft: 20,
              }}
            >
              <span>Send ready notification</span>
            </button>
          )}
        </Modal.Title>
      </Modal.Header>
      <div className="d-none d-print-block text-center">
        <img src={images.Logo} alt="TransferMyRx" width={290} />
        <h4>
          Transfer {props.requesttype === 1 ? "Out" : "In"} Request Details #
          {props?.request?.RequestId}
        </h4>
        <hr />
      </div>
      {(currentUser?.user?.roleId === 1 ||
        currentUser?.user?.roleId === 2 ||
        currentUser?.user?.roleId === 3) && (
        <div
          className={`alert alert-${getStatusStyle()} d-flex align-items-center d-print-none`}
          role="alert"
        >
          <i className={`${getStatusIcon()} me-3`}></i>
          {getStatusMessage()}

          {props.requesttype !== 1 && props?.request?.StatusId === 1 ? (
            <button
              className="btn btn-default me-2"
              onClick={() => props.handleAcceptTransferRequestModalShow(true)}
            >
              Accept
            </button>
          ) : null}
          {props.requesttype === 1 && props?.request?.StatusId === 2 ? (
            <button
              className="btn btn-primary me-2"
              onClick={() => {
                props.onHide();
                props.handleEditTransferRequestModalShow(true);
              }}
            >
              Edit & Submit
            </button>
          ) : null}
          {(props.requesttype === 1 && props?.request?.StatusId === 2) ||
          (props.requesttype !== 1 && props?.request?.StatusId === 1) ? (
            <button
              className="btn btn-danger"
              onClick={() => props.handleRejectTransferRequestModalShow(true)}
            >
              Reject
            </button>
          ) : null}
          {(props.requesttype === 1 && props?.request?.StatusId === 1) ||
          (props.requesttype !== 1 && props?.request?.StatusId === 2) ? (
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => props.handleCancelTransferRequestModalShow(true)}
              style={{ minWidth: 180 }}
            >
              Transfer Withdrawal
            </button>
          ) : null}
          {props?.request?.StatusId === 3 ? (
            <button
              type="button"
              className="btn btn-secondary d-print-none"
              onClick={() => {
                let element = document.getElementById("request-details");
                // let refillsElement = document.getElementById("refills-history");

                if (element) {
                  // refillsElement.style.display = "none";
                  // @ts-ignore
                  import("html2pdf.js").then((module) => {
                    const html2pdf = module.default;
                    element.style.padding = "20px";
                    html2pdf(element)
                      .from(element)
                      .set({ format: "A4", orientation: "portrait" })
                      .save(`transfer-request-details.pdf`);
                    // setTimeout(() => {
                    //   refillsElement.style.display = "flex";
                    // }, 1000);
                  });
                }
              }}
            >
              <i className="fa-solid fa-print me-3"></i>
              Print
            </button>
          ) : null}
        </div>
      )}
      <Modal.Body id="request-details">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="card mb-3">
              <div className="card-header">Transfering From Pharmacy</div>
              <div className="card-body">
                <h5 className="card-title">
                  {props?.request?.FromPharmacyBranchName || "-"}
                </h5>
                <p className="card-text">
                  {props?.request?.FromPharmacyBranchAddress ||
                  props?.request?.FromPharmacyBranchCityName ||
                  props?.request?.FromPharmacyBranchStateName
                    ? `${props?.request?.FromPharmacyBranchAddress}${
                        props?.request?.FromPharmacyBranchCityName
                          ? `${
                              props?.request?.FromPharmacyBranchAddress
                                ? ", "
                                : ""
                            }${props?.request?.FromPharmacyBranchCityName}`
                          : ""
                      }${
                        !!props?.request?.FromPharmacyBranchStateName
                          ? `${
                              props?.request?.FromPharmacyBranchAddress ||
                              props?.request?.FromPharmacyBranchCityName
                                ? ", "
                                : ""
                            }${props?.request?.FromPharmacyBranchStateName}`
                          : ""
                      }`
                    : "-"}
                </p>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-5 col-sm-12">Phone: </label>
                    <span className="col-md-7 col-sm-12">
                      {props?.request?.FromPharmacyBranchPhone || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-5 col-sm-12">Fax: </label>
                    <span className="col-md-7 col-sm-12">
                      {props?.request?.FromPharmacyBranchFax || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-5 col-sm-12">DEA: </label>
                    <span className="col-md-7 col-sm-12">
                      {props?.request?.FromPharmacyBranchDEANumber || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-5 col-sm-12">Email: </label>
                    <span className="col-md-7 col-sm-12">
                      {props?.request?.FromPharmacyBranchEmailAddress || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-5 col-sm-12">Pharmacist:</label>
                    <span className="col-md-7 col-sm-12">
                      {props?.request?.FromPharmacistName ||
                        props?.request?.integrationData
                          ?.fromPharmacyPharmacistName ||
                        props?.request?.integrationData?.changeStatusBy ||
                        "-"}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-5 col-sm-12">Website:</label>
                    <span className="col-md-7 col-sm-12">
                      {props?.request?.FromPharmacyWebSite || "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="card mb-3">
              <div className="card-header">Transfering To Pharmacy</div>
              <div className="card-body">
                <h5 className="card-title">
                  {props?.request?.ToPharmacyBranchName || "-"}
                </h5>
                <p className="card-text">
                  {props?.request?.ToPharmacyBranchAddress ||
                  props?.request?.ToPharmacyBranchCityName ||
                  props?.request?.ToPharmacyBranchStateName
                    ? `${props?.request?.ToPharmacyBranchAddress}${
                        props?.request?.ToPharmacyBranchCityName
                          ? `${
                              props?.request?.ToPharmacyBranchAddress
                                ? ", "
                                : ""
                            }${props?.request?.ToPharmacyBranchCityName}`
                          : ""
                      }${
                        !!props?.request?.ToPharmacyBranchStateName
                          ? `${
                              props?.request?.ToPharmacyBranchAddress ||
                              props?.request?.ToPharmacyBranchCityName
                                ? ", "
                                : ""
                            }${props?.request?.ToPharmacyBranchStateName}`
                          : ""
                      }`
                    : "-"}
                </p>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-5 col-sm-12">Phone: </label>
                    <span className="col-md-7 col-sm-12">
                      {props?.request?.ToPharmacyBranchPhone || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-5 col-sm-12">Fax: </label>
                    <span className="col-md-7 col-sm-12">
                      {props?.request?.ToPharmacyBranchFax || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-5 col-sm-12">DEA: </label>
                    <span className="col-md-7 col-sm-12">
                      {props?.request?.ToPharmacyBranchDEANumber || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-5 col-sm-12">Email: </label>
                    <span className="col-md-7 col-sm-12">
                      {props?.request?.ToPharmacyBranchEmailAddress || "-"}
                    </span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-5 col-sm-12">Pharmacist:</label>
                    <span className="col-md-7 col-sm-12">
                      {props?.request?.ToPharmacistName ||
                        props?.request?.integrationData
                          ?.toPharmacyPharmacistName ||
                        props?.request?.integrationData?.changeStatusBy}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-5 col-sm-12">Website:</label>
                    <span className="col-md-7 col-sm-12">
                      {props?.request?.ToPharmacyWebSite || "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {props?.request?.trxTraceId ? (
          <RequestWithTraceIdTop request={props.request} />
        ) : (
          <RequestWithOutTraceIdTop request={props.request} />
        )}
        {props?.request?.fileKey && (
          <h5
            className="card-title"
            onClick={() => window.open(props.request.fileKey, "_blank")}
            style={{
              color: "blue",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Attachment : Download
          </h5>
        )}
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <div className="card mb-3">
              <div className="card-header">Patient Information</div>
              <div className="card-body">
                <h5 className="card-title">
                  {props?.request?.PatientName || "-"}
                </h5>
                {props?.request?.PatientAddress && (
                  <p className="card-text">
                    {props?.request?.PatientAddress || "-"}
                  </p>
                )}
                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-5 col-sm-12">Mobile: </label>
                    <span className="col-md-7 col-sm-12">
                      {props?.request?.PatientMobile || "-"}
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <label className="col-md-5 col-sm-12">Date Of Birth:</label>
                    <span className="col-md-7 col-sm-12">
                      {moment(props?.request?.PatientDateOfBirth)
                        ?.format("MMMM DD YYYY")
                        .replace("Invalid date", "Never filled") || "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {props?.request?.trxTraceId ? (
            <>
              <RequestWithTraceIdBottom request={props?.request} />
            </>
          ) : (
            <RequestWithOutTraceIdBottom request={props?.request} />
          )}
        </div>

        {props?.request?.StatusId === 3 ? (
          <h6 className="signature d-none d-print-block mt-3">
            Receiving Pharmacist Signature:
            <hr className="align-text-top d-inline-block ms-3" />
          </h6>
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

const RequestWithTraceIdTop = ({ request }) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card mb-3">
            <div className="card-header">Rx Information</div>
            <div className="card-body">
              <div className="row">
                {request?.integrationData?.rxNumber && (
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-5 col-sm-12">Rx Number:</label>
                    <span className="col-md-7 col-sm-12">
                      {request?.integrationData?.rxNumber || "-"}
                    </span>
                  </div>
                )}

                {request?.integrationData?.dispensedDrugName && (
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-5 col-sm-12">
                      Dispensed Drug:
                    </label>
                    <span className="col-md-7 col-sm-12">
                      {request?.integrationData?.dispensedDrugName || "-"}
                    </span>
                  </div>
                )}
              </div>

              <div className="row">
                {request?.integrationData?.dispensedDrugManufacturerName && (
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-5 col-sm-12">
                      Dispensed Manufacturer:
                    </label>
                    <span className="col-md-7 col-sm-12">
                      {request?.integrationData
                        ?.dispensedDrugManufacturerName || "-"}
                    </span>
                  </div>
                )}

                {request?.integrationData?.rxIssueDate && (
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-5 col-sm-12">Date Written:</label>
                    <span className="col-md-7 col-sm-12">
                      {moment(request?.integrationData?.rxIssueDate)
                        ?.format("MMMM DD YYYY")
                        .replace("Invalid date", "Never filled") || "-"}
                    </span>
                  </div>
                )}
              </div>

              <div className="row">
                {request?.integrationData?.expirationDate && (
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-5 col-sm-12">
                      Expiration Date:
                    </label>
                    <span className="col-md-7 col-sm-12">
                      {moment(request?.integrationData?.expirationDate)
                        ?.format("MMMM DD YYYY")
                        .replace("Invalid date", "Never filled") || "-"}
                    </span>
                  </div>
                )}

                {request?.integrationData?.dispenseAsWrittenTypeName && (
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-5 col-sm-12">
                      Dispense as Written (DAW):
                    </label>
                    <span className="col-md-7 col-sm-12">
                      {request?.integrationData?.dispenseAsWrittenTypeName ||
                        "-"}
                    </span>
                  </div>
                )}
              </div>

              <div className="row">
                {request?.integrationData?.prescribedDrugName ||
                  (request?.integrationData?.drugName && (
                    <div className="col-md-6 col-sm-12">
                      <label className="col-md-5 col-sm-12">
                        Prescribed Drug:
                      </label>
                      <span className="col-md-7 col-sm-12">
                        {request?.integrationData?.prescribedDrugName ||
                          request?.integrationData?.drugName ||
                          "-"}
                      </span>
                    </div>
                  ))}
                {request?.integrationData?.qtyPrescribed !== null && (
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-5 col-sm-12">
                      Qty Prescribed:
                    </label>
                    <span className="col-md-7 col-sm-12">
                      {request?.integrationData?.qtyPrescribed || 0}
                    </span>
                  </div>
                )}
              </div>

              <div className="row">
                {request?.integrationData?.originalRefills !== null && (
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-5 col-sm-12">
                      Original Refills:
                    </label>
                    <span className="col-md-7 col-sm-12">
                      {request?.integrationData?.originalRefills || 0}
                    </span>
                  </div>
                )}

                {request?.integrationData?.refillsRemaining !== null && (
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-5 col-sm-12">
                      Refills Remaining:
                    </label>
                    <span className="col-md-7 col-sm-12">
                      {request?.integrationData?.refillsRemaining || 0}
                    </span>
                  </div>
                )}
              </div>
              <div className="row">
                {request?.integrationData?.qtyRemaining !== null && (
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-5 col-sm-12">Qty Remaining:</label>
                    <span className="col-md-7 col-sm-12">
                      {request?.integrationData?.qtyRemaining || 0}
                    </span>
                  </div>
                )}

                {request?.integrationData?.firstFillDate && (
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-5 col-sm-12">
                      First Fill Date:
                    </label>
                    <span className="col-md-7 col-sm-12">
                      {moment(request?.integrationData?.firstFillDate)
                        ?.format("MMMM DD YYYY")
                        .replace("Invalid date", "Never filled") || "-"}
                    </span>
                  </div>
                )}
              </div>

              <div className="row">
                {request?.integrationData?.directions && (
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-5 col-sm-12">Directions:</label>
                    <span className="col-md-7 col-sm-12">
                      {request?.integrationData?.directions || "-"}
                    </span>
                  </div>
                )}

                {request?.integrationData?.lastFillDate && (
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-5 col-sm-12">
                      Last Fill Date:
                    </label>
                    <span className="col-md-7 col-sm-12">
                      {moment(request?.integrationData?.lastFillDate)
                        ?.format("MMMM DD YYYY")
                        .replace("Invalid date", "Never filled") || "-"}
                    </span>
                  </div>
                )}
              </div>

              {request?.integrationData.notes && (
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label className="col-md-5 col-sm-12">Notes:</label>
                    <span className="col-md-7 col-sm-12">
                      {request?.integrationData.notes || "-"}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const RequestWithOutTraceIdTop = ({ request }) => {
  return (
    <div className="row">
      <div className="col-sm-12">
        <div className="card mb-3">
          <div className="card-header">Rx Information</div>
          <div className="card-body">
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <label className="col-md-5 col-sm-12">Rx Number:</label>
                <span className="col-md-7 col-sm-12">
                  {request?.RxNumber || "-"}
                </span>
              </div>
              <div className="col-md-6 col-sm-12">
                <label className="col-md-5 col-sm-12">Dispensed Drug:</label>
                <span className="col-md-7 col-sm-12">
                  {request?.DispensedDrugName || "-"}
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <label className="col-md-5 col-sm-12">
                  Dispensed Manufacturer:
                </label>
                <span className="col-md-7 col-sm-12">
                  {request?.DispensedDrugManufacturerName || "-"}
                </span>
              </div>
              <div className="col-md-6 col-sm-12">
                <label className="col-md-5 col-sm-12">Date Written:</label>
                <span className="col-md-7 col-sm-12">
                  {moment(request?.RxIssueDate)
                    ?.format("MMMM DD YYYY")
                    .replace("Invalid date", "Never filled") || "-"}
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <label className="col-md-5 col-sm-12">Expiration Date:</label>
                <span className="col-md-7 col-sm-12">
                  {moment(request?.ExpirationDate)
                    ?.format("MMMM DD YYYY")
                    .replace("Invalid date", "Never filled") || "-"}
                </span>
              </div>
              <div className="col-md-6 col-sm-12">
                <label className="col-md-5 col-sm-12">
                  Dispense as Written (DAW):
                </label>
                <span className="col-md-7 col-sm-12">
                  {request?.DispenseAsWrittenTypeName || "-"}
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <label className="col-md-5 col-sm-12">Prescribed Drug:</label>
                <span className="col-md-7 col-sm-12">
                  {request?.PrescribedDrugName || "-"}
                </span>
              </div>
              <div className="col-md-6 col-sm-12">
                <label className="col-md-5 col-sm-12">Qty Prescribed:</label>
                <span className="col-md-7 col-sm-12">
                  {request?.QtyPrescribed ||
                    request?.integrationData?.qtyPrescribed ||
                    0}
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <label className="col-md-5 col-sm-12">Original Refills:</label>
                <span className="col-md-7 col-sm-12">
                  {request?.OriginalRefills ||
                    request?.integrationData?.originalRefills ||
                    0}
                </span>
              </div>
              <div className="col-md-6 col-sm-12">
                <label className="col-md-5 col-sm-12">Refills Remaining:</label>
                <span className="col-md-7 col-sm-12">
                  {request?.RefillsRemaining ||
                    request?.integrationData?.refillsRemaining ||
                    0}
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <label className="col-md-5 col-sm-12">Qty Remaining:</label>
                <span className="col-md-7 col-sm-12">
                  {request?.QtyRemaining ||
                    request?.integrationData?.qtyRemaining ||
                    0}
                </span>
              </div>

              <div className="col-md-6 col-sm-12">
                <label className="col-md-5 col-sm-12">First Fill Date:</label>
                <span className="col-md-7 col-sm-12">
                  {moment(
                    request?.FirstFillDate ||
                      request?.integrationData?.firstFillDate
                  )
                    ?.format("MMMM DD YYYY")
                    .replace("Invalid date", "Never filled") || "-"}
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <label className="col-md-5 col-sm-12">Directions:</label>
                <span className="col-md-7 col-sm-12">
                  {request?.Directions || "-"}
                </span>
              </div>

              <div className="col-md-6 col-sm-12">
                <label className="col-md-5 col-sm-12">Last Fill Date:</label>
                <span className="col-md-7 col-sm-12">
                  {moment(
                    request?.LastFillDate ||
                      request?.integrationData?.lastFillDate
                  )
                    ?.format("MMMM DD YYYY")
                    .replace("Invalid date", "Never filled") || "-"}
                </span>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-sm-12">
                <label className="col-md-5 col-sm-12">Notes:</label>
                <span className="col-md-7 col-sm-12">
                  {request?.Notes || "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const RequestWithOutTraceIdBottom = ({ request }) => {
  return (
    <>
      {request?.PrescriberName && (
        <div className="col-md-6 col-sm-12">
          <div className="card mb-3">
            <div className="card-header">Prescriber Information</div>
            <div className="card-body">
              <h5 className="card-title">{request?.PrescriberName || "-"}</h5>
              <p className="card-text">{request?.PrescriberAddress || "-"}</p>

              <div className="row">
                <div className="col-sm-12">
                  <label className="col-md-5 col-sm-12">Supervisor: </label>
                  <span className="col-md-7 col-sm-12">
                    {request?.PrescriberSupervisorName || "-"}
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <label className="col-md-5 col-sm-12">Mobile: </label>
                  <span className="col-md-7 col-sm-12">
                    {request?.PrescriberMobile || "-"}
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <label className="col-md-5 col-sm-12">Fax: </label>
                  <span className="col-md-7 col-sm-12">
                    {request?.PrescriberFax || "-"}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <label className="col-md-5 col-sm-12">DEA: </label>
                  <span className="col-md-7 col-sm-12">
                    {request?.PrescriberDEANumber || "-"}
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <label className="col-md-5 col-sm-12">NPI: </label>
                  <span className="col-md-7 col-sm-12">
                    {request?.PrescriberNPI || "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {request?.drugRefillsHistory?.length > 0 ? (
        <div className="col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">Drug Refills History</div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Drug Name</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {request?.drugRefillsHistory.map((refill, index) => (
                      <tr key={index}>
                        <td>{new Date(refill.Date).toLocaleDateString()}</td>
                        <td>{refill.DrugName}</td>
                        <td>{refill.Qty}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const RequestWithTraceIdBottom = ({ request }) => {
  return (
    <>
      {(request?.integrationData?.prescriber?.label ||
        request?.integrationData?.prescriber?.name) && (
        <div className="col-md-6 col-sm-12">
          <div className="card mb-3">
            <div className="card-header">Prescriber Information</div>
            <div className="card-body">
              <h5 className="card-title">
                {request?.integrationData?.prescriber?.name ||
                  request?.integrationData?.prescriber?.label ||
                  "-"}
              </h5>
              <p className="card-text">
                {request?.integrationData?.prescriber?.address || "-"}
              </p>

              <div className="row">
                <div className="col-sm-12">
                  <label className="col-md-5 col-sm-12">Supervisor: </label>
                  <span className="col-md-7 col-sm-12">
                    {request?.integrationData?.prescriberSupervisor?.name ||
                      request?.integrationData?.prescriberSupervisor?.label ||
                      "-"}
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <label className="col-md-5 col-sm-12">Mobile: </label>
                  <span className="col-md-7 col-sm-12">
                    {request?.integrationData?.prescriber?.phoneNumber ||
                      request?.integrationData?.prescriber?.phone ||
                      "-"}
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <label className="col-md-5 col-sm-12">Fax: </label>
                  <span className="col-md-7 col-sm-12">
                    {request?.integrationData?.prescriber?.fax || "-"}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <label className="col-md-5 col-sm-12">DEA: </label>
                  <span className="col-md-7 col-sm-12">
                    {request?.integrationData?.prescriber?.dea || "-"}
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <label className="col-md-5 col-sm-12">NPI: </label>
                  <span className="col-md-7 col-sm-12">
                    {request?.integrationData?.prescriber?.npi || "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {request?.integrationData?.drugRefillsHistory?.length > 0 ? (
        <div className="col-md-12 col-sm-12">
          <div className="card">
            <div className="card-header">Drug Refills History</div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Drug Name</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {request?.integrationData?.drugRefillsHistory.map(
                      (refill, index) => (
                        <tr key={index}>
                          <td>{new Date(refill.Date).toLocaleDateString()}</td>
                          <td>{refill.DrugName}</td>
                          <td>{refill.Qty}</td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ViewTransferRequestModal;
